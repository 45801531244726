import { useState, useEffect } from "react"
import icon_happy from './image/icon_happy.webp';
import './style.scss';

import TokenManagerMiddleware from '../../context/TokenManagerMiddleware'


// Форма
import { useForm } from 'react-hook-form';
// инпут
import { Controller } from 'react-hook-form'; // контроллер для инпута






const Quiz = () => {


  // const profile = JSON.parse(localStorage.getItem("profile"));


  const [quiz, setQuiz] = useState(1); // Определяем какой квиз будет открыт
  const [isDisabled, setIsDisabled] = useState(false); // у кнопки изначально отключена настройка выключения кнопки


  const [content, setContent] = useState({ // данные user
    username: '',
    country: '',
    phone: '',
    birth_date: '',
  })


  useEffect(() => {
    //Проверка для квиза, если выключен то переходим в кабинет
    // if (isQuiz === false) {
    //Переход в quiz (c перезагрузкой)
    //   window.location.href = '/my_cabinet';
    // }
  }, [])



  const Next = () => {
    // Переход к следующему квизу
    setQuiz((prevQuiz) => Math.min(prevQuiz + 1, 5)); // Ограничиваем до 3
  };






  // -----Формы-----
  // настройки для формы
  const { register,
    control,
    handleSubmit,
    setError,
    formState: { errors }

  } = useForm({
    mode: 'onBlur',
  });






  // отправляем в django и выключаем квиз
  async function onSubmit(con) {

    try {
      const otvet = await TokenManagerMiddleware.post(`/api/quiz/`, con, { headers: { 'content-type': 'multipart/form-data' } })


      //Переход в quiz (c перезагрузкой)
      window.location.href = '/my_cabinet';


    } catch (e) {
      if (e.response.status === 401) {
        console.log('Error 401, ')
      }
    };

  }




  function handleChange(data) {

    let d, y;

    // Перебираем ключи в объекте
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        d = key;          // Ключ
        y = data[key];    // Значение
      }
    }

    setContent(prevNote => {
      const updatedContent = {
        ...prevNote, [d]: y

      }



      if (d === 'birth_date') {
        setIsDisabled(true); // выключить кнопку 
        onSubmit(updatedContent)
      } else {
        Next();
      }

      return updatedContent; // возвращаем обновленное состояние

    });

  }
















  return (
    <div className='bg_quiz'>

      <div className='wrapper_quiz'>

        <div className='quiz'>





          {quiz === 1 && (
            <>
              <div className='title_quiz'>
                Поздравляем!
                <img src={icon_happy} />
              </div>

              <div className='reg'>Вы успешно зарегистрировались</div>
              <div className='post_mail'>Пароль отправили на вашу почту</div>

              <button onClick={() => Next()} className='button_quiz'>Далее</button>
            </>
          )}




          {quiz === 2 && (
            <>
              <div className='title_quiz'>
                Давайте знакомиться
              </div>

              {/* контроллер от формы */}
              <Controller
                control={control}
                name='username'
                render={({ field: { onChange, onBlur, value = '' } }) => (



                  <input
                    className='input_quiz'
                    type='text'
                    onBlur={onBlur}

                    {...register('username', {
                      required: 'Минимум 2 символа',
                      minLength: {
                        value: 2,
                        message: 'Минимум 2 символa'
                      }
                    })
                    }

                    // onChange={e => onChange(e.target.value)}
                    // onChangeText={(text) => onChange(text)}
                    onChange={onChange}
                    value={value}
                    placeholder={'Как вас зовут?'}
                  />

                )}
              />

              {/*ошибка*/}
              {errors?.username && <div className='error_quiz'>
                {errors?.username?.message}
              </div>}



              <button onClick={handleSubmit(handleChange)} className='button_quiz'>Далее</button>
            </>
          )}







          {quiz === 3 && (

            <>
              <div className='dots_quiz'>
                <div className='dot_active'></div>
                <div className='dot'></div>
                <div className='dot'></div>
              </div>

              <div className='title_quiz'>
                Откуда вы?
              </div>

              {/* контроллер от формы */}
              <Controller
                control={control}
                name='country'
                render={({ field: { onChange, onBlur, value = '' } }) => (



                  <input
                    className='input_quiz'
                    type='text'
                    onBlur={onBlur}

                    {...register('country', {
                      required: 'Введите название страны',
                      minLength: {
                        value: 2,
                        message: 'Введите название страны'
                      }
                    })
                    }

                    onChange={onChange}
                    value={value}
                    placeholder={'Введите вашу страну'}
                  />

                )}
              />

              {/*ошибка*/}
              {errors?.country && <div className='error_quiz'>
                {errors?.country?.message}
              </div>}


              <button onClick={handleSubmit(handleChange)} className='button_quiz'>Далее</button>
            </>
          )
          }




          {quiz === 4 && (

            <>
              <div className='dots_quiz'>
                <div className='dot'></div>
                <div className='dot_active'></div>
                <div className='dot'></div>
              </div>

              <div className='title_quiz'>
                Введите ваш номер телефона
              </div>

              {/* контроллер от формы */}
              <Controller
                control={control}
                name='phone'
                render={({ field: { onChange, onBlur, value = '' } }) => (



                  <input
                    className='input_quiz'
                    type='text'
                    onBlur={onBlur}

                    {...register('country', {
                      required: 'Введите ваш номер телефона',
                      minLength: {
                        value: 2,
                        message: 'Введите ваш номер телефона'
                      }
                    })
                    }

                    onChange={onChange}
                    value={value}
                    placeholder={'+7 000 000 00 00'}
                  />

                )}
              />

              {/*ошибка*/}
              {errors?.phone && <div className='error_quiz'>
                {errors?.phone?.message}
              </div>}


              <button onClick={handleSubmit(handleChange)} className='button_quiz'>Далее</button>
            </>
          )
          }






          {quiz === 5 && (

            <>
              <div className='dots_quiz'>
                <div className='dot'></div>
                <div className='dot'></div>
                <div className='dot_active'></div>
              </div>

              <div className='title_quiz'>
                Дата рождения:
              </div>

              {/* контроллер от формы */}
              <Controller
                control={control}
                name='birth_date'
                render={({ field: { onChange, onBlur, value = '' } }) => (



                  <input
                    className='input_quiz'
                    type='date'
                    onBlur={onBlur}

                    {...register('country', {
                      required: 'Введите дату рождения',
                      minLength: {
                        value: 2,
                        message: 'Введите дату рождения'
                      }
                    })
                    }

                    onChange={onChange}
                    value={value}
                    placeholder={'00.00.0000'}
                  />

                )}
              />

              {/*ошибка*/}
              {errors?.date && <div className='error_quiz'>
                {errors?.date?.message}
              </div>}


              <button onClick={handleSubmit(handleChange)} className='button_quiz' disabled={isDisabled}>Далее</button>
            </>
          )
          }





        </div>

      </div>


    </div >
  )

}





export default Quiz;
