import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Buffer } from 'buffer'; // декодирование токена
import TokenManagerMiddleware from './TokenManagerMiddleware'


// Context переменная которая может проходить сквозь все компоненты
export const AuthContext = createContext();


const AuthProvider = ({ children }) => {

  //(Нигде пока не используется)
  const [accessToken, setAccessToken] = useState(null); // access токен
  const [refreshToken, setRefreshToken] = useState(null); // refresh токен
  const [isLoading, setIsLoading] = useState(false); // 


  const [profile, setProfile] = useState(
    {
      username: '',
      courses: [], //если не вставить эти данные может умереть
      title: '',
    }
  )

  const [courses, setCourses] = useState(null)




  //Загружаем данные (используется в загрузке данных в my_cabinet.jsx)
  async function Reload() {
    console.log('--Запустился Reload - загружаем данные')
    try {
      setIsLoading(true)

      //загружаем профиль если не грузится делаем refresh токена
      await TokenManagerMiddleware.get('/api/profile/')
        .then(response => {
          const data = response.data

          // сохраняем в LocalStorage данные профиля
          // localStorage.setItem('profile', JSON.stringify(data));

          // сохраняем данные в useState
          setProfile(data)

          setIsLoading(false)
        })
        .catch(error => {
          console.error('Ошибка:', error);
        });

    } catch (error) {
      console.log('Access токен устарел');
    }
  }




  // Cохранение токенов в useStat и localStorage 
  // используется в login и register
  async function saveTokens(tokens) {
    console.log('---Запустилась saveTokens-функция')

    // Сохраняем токены AsyncStorage
    localStorage.setItem('refreshToken', tokens.refresh);
    localStorage.setItem('accessToken', tokens.access);

  }




  // Выход
  const logout = () => {
    console.log('---Выход')

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');

  }


  //Автозапуск
  useEffect(() => {

    console.log('---Запустилась useEffect функция')


    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      console.log('Токен существует:', refreshToken);
      Reload()
    } else {
      console.log('Токен не найден');
    }




  }, []);




  // Передаём контекст дочерним компонентам
  return (
    <AuthContext.Provider value={{

      accessToken,
      saveTokens,
      logout,
      Reload,
      profile,
      courses,
      isLoading,

    }}>

      {children}

    </AuthContext.Provider>
  );
};


export default AuthProvider;
