import { useContext, useState } from 'react'
import axios from "axios";


import { useForm } from 'react-hook-form'; // форма
import { Controller } from 'react-hook-form'; // Контроллер для инпута


import { Link } from 'react-router-dom';


import logo from '../../../components/admin/adm_navbar/images/logo.svg'

import './style.scss'


// компоненты
import { AuthContext } from '../../../context/authContext' // Контекст



const Register = () => {

  //Контекст
  const { saveTokens } = useContext(AuthContext);

  // on/off button
  // у кнопки изначально отключена настройка выключения кнопки
  const [isDisabled, setIsDisabled] = useState(false);


  // для формы
  const { register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors } } = useForm({ mode: 'onBlur' });




  // -----Функция регистрации нового пользователя--------------------------
  // Отправка e-mail в django -> принимае токены -> cохраняем в контексте
  async function sendEmail(email) {

    setIsDisabled(true); // выключить кнопку 

    try {
      const otvet = await axios.post(`/api/register/`, { email })

      //сохраняем токены
      saveTokens(otvet.data)

      //Переход в quiz (c перезагрузкой)
      window.location.href = '/quiz';

    } catch (e) {
      console.log(`Login error ${e}`);

      if (e.response.status === 400) {
        // Сообщаем пользователю что пользователь с таким e-mail уже существует
        setError('root.serverError', {
          type: e.response.status,
          message: 'Пользователь с таким e-mail уже есть.',
        })
      } else {
        // Сообщаем пользователю что какаято другая ошибка
        setError('root.serverError', {
          type: e.response.status,
          message: 'Непредвиденная ошибка, перезагрузите\nсайт,  и зарегистрируйтесь заново ',
        })
      }
    };

  }



  return (
    <div className="bg_form">

      <div className="wrapper_form">


        {/* dставляем лого */}

        <div><img src={logo} className="lo" /> </div>


        <div className="form_register">



          <Controller
            control={control}
            name='email'
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                className="input"
                type='email'
                onBlur={onBlur}
                {...register("email", {
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
                // onChange={(text) => onChange(text.toLowerCase())}
                // эта история делает нижний регистр при регистрации
                onChange={(e) => onChange(e.target.value.toLowerCase())}
                value={value}
                placeholder='Введите e-mail'
              />
            )}
          />


          {/*ошибка*/}
          {errors.email && <div className='error_form'>
            Введите е-mail в формате: name@name.com
          </div>}

          {/*ошибка 401, непредвиденная ошибка*/}
          {errors.root?.serverError.type === 401 && <div className='error_form'>
            {errors.root.serverError.message}
          </div>}

          {/*ошибка 400, такой e-mail уже был*/}
          {errors.root?.serverError.type === 400 && <div className='error_form'>
            {errors.root.serverError.message}
          </div>}


          <a
            onClick={handleSubmit((data) => sendEmail(data.email))}
            className='bottom_form'

            style={{
              backgroundColor: isDisabled ? 'grey' : 'green',
              pointerEvents: isDisabled ? 'none' : 'auto', // отключаем клик при отключенном состоянии
            }}
            disabled={isDisabled}
          >
            Зарегистрироваться
          </a>

          <Link
            className='link_problem'
            to="/login" >Уже зарегистрированны? Вход
          </Link>

          <a
            href="https://t.me/natalia_promolodost_assistant"
            className='link_problem'
          >
            Возникли проблемы?
          </a>



        </div>


      </div>




    </div>
  )
};

export default Register;
